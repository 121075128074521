const retrieveBranding = () => {
  // use pathmate as default branding
  let branding = "pathmate"
  const url = new URL(window.location.href);
  const domain = url.hostname;

  if (domain.endsWith("manoa.app")) {
    branding = "manoa"
  } else if (domain.endsWith("sonoa.app")) {
    branding = "sonoa"
  } else if (domain.endsWith("sanitas-coach-app.com")) {
    branding = "sanitas"
  } else if (domain.endsWith("veroval-lea.com")) {
    branding = "veroval"
  }
  return branding;
};

const retrieveLocale = () => {
  const availableLanguages = ['de-DE', 'de-CH', 'en-GB', 'fr-CH', 'it-CH'];
  var browserLanguage = navigator && navigator.language ? navigator.language : false; // sometimes only display language of browser ui
  // try to pick first language from user preferred languages array
  if (navigator && navigator.languages && navigator.languages.length > 0) {
    browserLanguage = navigator.languages[0]
  }
  var matchingLanguages = [];
  console.log(`User preferred language: ${browserLanguage}`);

  // Browser doesnt provide language
  if (browserLanguage === false) {
    console.warn("Unable to retrieve language from browser. Switch to default language.");
    return availableLanguages[0];
  }

  // Match browser language to available languages
  if (browserLanguage.length > 2) {
    matchingLanguages = availableLanguages.filter(language => language.toLowerCase() === browserLanguage.toLowerCase());
    if (matchingLanguages.length > 0) {
      console.log(`Language selected: ${matchingLanguages[0]}`);
      return matchingLanguages[0];
    }
  }

  // No match of found -> try to match first 2 chars instead
  console.log(`No direct match of ${browserLanguage} with available languages.`);
  const testLanguage = browserLanguage.substring(0,2);
  console.log(`Try to match ${testLanguage} instead.`);
  matchingLanguages = availableLanguages.filter(language => language.substring(0,2).toLowerCase() === testLanguage.toLowerCase());
  if (matchingLanguages.length > 0) {
    console.log(`Language selected: ${matchingLanguages[0]}`);
    return matchingLanguages[0];
  }

  // Fallback
  console.warn(`No matching language for ${browserLanguage} or ${testLanguage} found. Switch to default language.`);
  return availableLanguages[0];
};

const AppConfig = {
  debug: false,
  brand: retrieveBranding(),
  locale: retrieveLocale(),
  privacyLink: 'https://my.pathmate.app/veroval-lea/legals',
  tracking: true,
  trackingKey: '1dbf1b34eda0d4afcaf9d76504683cc41bd11b06' // insert 1dbf1b34eda0d4afcaf9d76504683cc41bd11b06 for property "Visiten Website" (https://analytics.pathmate.online/)
};

export default AppConfig;
